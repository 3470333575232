import OSS from 'ali-oss'
import { aliSTS } from '@/api/common'
import { v4 as uuidV4 } from 'uuid'

const mode = process.env.VUE_APP_OSS_MODE
const baseUrl = process.env.VUE_APP_IMAGE_BASE
const region = process.env.VUE_APP_OSS_REGION
const bucket = process.env.VUE_APP_OSS_BUCKET
const endpoint = process.env.VUE_APP_OSS_ENDPOINT

// let instance = null

const ossClient = {
  instance: null,
  put: function (filePath, file) {
    const action = client => client.put(filePath, file)
    return applyTokenDo(action)
    // .then(res => { console.log('---- upload: ', res); return res })
  },
  baseUrl,
  baseDir: mode === 'prod' ? 'prod' : 'test',
  genFilePath,
  getFileExtention,
}

/**
 * 刷新Token
 * @returns STS鉴权报文
 */
function refreshSTSToken () {
  return aliSTS().then(res => {
    if (!res.data?.status) {
      throw new Error('STS error')
    }
    return res.data
  })
}

/**
 * 请求回调
 * @param {Function} action
 * @returns {*}
 */
function applyTokenDo (action) {
  if (ossClient.instance) {
    return action(ossClient.instance)
  }
  return refreshSTSToken().then(creds => {
    ossClient.baseDir = creds.baseDir
    ossClient.instance = new OSS({
      region: region,
      bucket: bucket,
      endpoint: endpoint,
      accessKeyId: creds.accessKeyId,
      accessKeySecret: creds.accessKeySecret,
      stsToken: creds.securityToken,
      refreshSTSToken: refreshSTSToken,
    })
    return action(ossClient.instance)
  })
}

/**
 * 生成文件路径
 * @param {String} moduleName - 模块名称
 * @param {String} fileName - 文件名
 * @param {Object} options
 * @param {String?} options.extention - 文件后缀
 */
function genFilePath (moduleName, fileName, options) {
  const extention = options?.extention || getFileExtention(fileName)

  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth() + 1
  const day = now.getDate()
  const timeString = `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}`

  const separator = moduleName.endsWith('/') ? '' : '/'
  const newFileName = uuidV4()
  // baseDir = `test/personalization/20241205`
  // fileDir = `1a/d3` // 1a328d0f-c0f8-4a0d-a0d8-a0d8a0d8a0d3.jpg
  const baseDir = `${ossClient.baseDir}/${moduleName}${separator}${timeString}`
  const fileDir = `${newFileName.substring(0, 2)}/${newFileName.substring(newFileName.length - 2)}`
  return `${baseDir}/${fileDir}/${newFileName}${extention}`
}

/**
 * 获取文件后缀
 * @param {String} fileName - 文件名
 * @returns
 */
function getFileExtention (fileName) {
  const idx = fileName.lastIndexOf('.')
  return idx < 0 ? '' : fileName.substring(idx).toLowerCase()
}

window.ossClient = ossClient

export default ossClient
