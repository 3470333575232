<template>
  <el-dialog
    title="基础性作业版本"
    :visible.sync="dialogVisible"
    width="540px">
    <div class="material-box">
      <div class="type">
        <div class="type-title">科目：</div>
        <div class="type-item">
          <el-cascader
            ref="subjects"
            placeholder="请选择"
            v-model="subject"
            :key="subjectKey"
            :options="subjectList"
            :props="{ label: 'subjectValue', value: 'id' }"
            @change="subjectChange"
          />
        </div>
      </div>
      <div class="type types">
        <div class="type-title">版本：</div>
        <div class="type-item">
          <el-cascader
            ref="editions"
            v-model="edition"
            :options="editionList"
            :key="editionKey"
            :props="{ label: 'name', value: 'id', children: 'list' }"
            @change="editionChange"
          />
        </div>
      </div>

      <div class="type types editions">
        <div class="type-title">教辅：</div>
        <div class="type-item material-item" v-if="materialList && materialList.length > 0">
          <div class="mi" v-for="item in materialList" :key="item.id" @click="selectMaterial(item)">
            <div class="type-item">{{ item.standardName }}</div>
            <img class="img" :src="item.url" alt="">
            <div class="materialActive" v-if="item.id === matrial">
              <img class="img2" src="~@/assets/images/selected.png" alt="">
            </div>
            <div class="name">
              {{ item.name }}
              <template v-if="item.reviseVersion">(修订版次：{{ item.reviseVersion }})</template>
            </div>
          </div>
        </div>
        <div v-else>暂无</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  commonSubsectionList
} from '@/api/question'
import {
  standardMaterialList,
  basicMaterialList,
  saveMaterialRecord
} from '@/api/changeMaterial'
export default {
  data () {
    return {
      dialogVisible: false,
      subjectList: [],
      subject: [],
      editionList: [],
      edition: [],
      materialList: [],
      matrial: null,
      materialName: null,
      materialCoverUrl: null,
      materialType: null, // 教材（练习册）类型
      standardName: null,
      subjectKey: 0,
      editionKey: 0,
      selectData: {
        editionGradeValue: null,
        editionName: null,
        subjectId: null,
        subjectName: null,
        teachingMaterialId: null,
        materialType: null,
      },
      infoData: {},
      initData: { // 初始值，判断是否做了修改
        subsectionId: null,
        standardMaterialId: null,
        materialId: null
      }
    }
  },
  watch: {
    subjectList () {
      this.subjectKey++
    },
    editionList () {
      this.editionKey++
    }
  },
  methods: {
    init () {
      this.subjectList = []
      this.subject = []
      this.editionList = []
      this.edition = []
      this.materialList = []
      this.matrial = null
      this.materialName = null
      this.materialCoverUrl = null
      this.materialType = null
      this.standardName = null
    },
    show (e) {
      if (e) {
        this.initData.subsectionId = e.subsectionId
        this.initData.standardMaterialId = e.standardMaterialId
        this.initData.materialId = e.materialId
        this.infoData = e
      }
      this.init()
      this.getSubject()
      this.dialogVisible = true
    },
    handSubmit () {
      if (this.subject.length === 0) {
        return this.$message.warning('请选择学段科目')
      }
      if (this.edition.length === 0) {
        return this.$message.warning('请选择教材版本')
      }
      if (!this.matrial) {
        return this.$message.warning('请选择教辅')
      }
      // if (this.initData.subsectionId === this.infoData.subsectionId && this.initData.standardMaterialId === this.infoData.standardMaterialId && this.initData.materialId === this.infoData.materialId) {
      //   return this.$message.warning('本次未作修改')
      // }
      if (this.initData.materialId === this.matrial) {
        return this.$message.warning('本次未作修改')
      }

      // type: 类型（NORMAL：普通教辅；BASE：基础作业教辅；CARRYING_PAPER：夹卷；）
      const params = {
        subsectionId: this.subject[1],
        materialId: this.matrial,
        standardMaterialId: this.edition[1],
        materialName: this.materialName,
        materialCoverUrl: this.materialCoverUrl,
        standardName: this.standardName,
        type: this.materialType,
      }
      saveMaterialRecord(params).then(res => {
        if (res.code === 0) {
          this.dialogVisible = false
          this.$emit('change', this.selectData)
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('操作失败')
      })
    },
    // 查找科目回显
    findSubjectData (tree, id, path = []) {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i]
        if (item.id === id) {
          path.unshift(item.id)
          return path
        } else if (item.children && item.children.length) {
          this.findSubjectData(item.children, id, path)
          if (path.length) {
            path.unshift(item.id)
            return path
          }
        }
      }
    },
    // 查找教材回显
    findEditionData (tree, id, path = []) {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i]
        if (item.id === id) {
          path.unshift(item.id)
          return path
        } else if (item.list && item.list.length) {
          this.findEditionData(item.list, id, path)
          if (path.length) {
            path.unshift(item.id)
            return path
          }
        }
      }
    },
    getSubject () {
      return commonSubsectionList().then(res => {
        if (res.code === 0) {
          this.subjectList = res.data.map(m => ({
            id: 's' + m.sort,
            subjectValue: m.subsection,
            children: m.subjects
          }))
          if (this.infoData.subsectionId) {
            this.subject = this.findSubjectData(this.subjectList, this.infoData.subsectionId)
            this.getEdition(this.infoData.subsectionId)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    subjectChange () {
      this.infoData = {}
      const node = this.$refs.subjects.getCheckedNodes()
      const eg = node[0].pathLabels[1]
      this.selectData.subjectName = eg
      this.selectData.subjectId = this.subject[1]
      this.editionList = []
      this.edition = []
      this.materialList = []
      this.matrial = null
      this.getEdition(this.subject[1])
    },
    getEdition (subsectionId) {
      return standardMaterialList(subsectionId).then(res => {
        if (res.code === 0) {
          const list = res.data || []
          list.forEach((m, index) => { m.id = 'e' + (index + 1) })
          this.editionList = list
          if (this.infoData.standardMaterialId) {
            this.edition = this.findEditionData(this.editionList, this.infoData.standardMaterialId)
            this.getBasicMaterialList(this.infoData.standardMaterialId)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    editionChange () {
      this.infoData = {}
      const node = this.$refs.editions.getCheckedNodes()
      const eg = node[0].pathLabels[0] + node[0].pathLabels[1]
      this.selectData.editionGradeValue = this.edition[1]
      this.selectData.editionName = eg
      this.materialList = []
      this.matrial = null
      this.getBasicMaterialList(this.edition[1])
    },
    selectMaterial (item) {
      console.log('--- selectMaterial:', item)
      this.matrial = item.id
      this.materialName = item.name
      this.materialCoverUrl = item.url
      this.materialType = item.type
      this.standardName = item.standardName
      this.selectData.teachingMaterialId = item.id
      this.selectData.materialType = item.type
    },
    getBasicMaterialList (standardMaterialId) {
      basicMaterialList(standardMaterialId).then(res => {
        if (res.code === 0) {
          this.materialList = res.data
          if (this.infoData.materialId) {
            this.matrial = this.infoData.materialId
            this.materialName = this.infoData.materialName
            this.materialCoverUrl = this.infoData.materialCoverUrl
            this.materialType = this.infoData.type
            this.standardName = this.infoData.standardName
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.material-box{
  padding: 0 10%;
  max-height: 60vh;
  overflow: auto;
}
.type{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .type-title{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    white-space: nowrap;
    margin-right: 10px;
  }
  .type-item{
    display: flex;
    flex-wrap: wrap;
    div{
      font-size: 14px;
      font-weight: 500;
      color: #666666;
    }
    div:hover{
      cursor: pointer;
    }
    .typeActive{
      color: #18C2C2;
      transform: scale(1.1);
      transition: 0.5s;
      font-weight: 600;
    }
  }
}
.types{
  margin-bottom: 30px;
}
.material-item{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .mi{
    width: 100px;
    // height: 100px;
    border-radius: 4px;
    // overflow: hidden;
    margin: 0 10px 10px 0;
    flex-shrink: 0;
    position: relative;
    .type-item{
      position: absolute;
      font-size: 20px;
      padding: 4px 6px;
      background-color: rgba(81, 155, 235, 0.5);
      top: 0;
      left: 0;
      color: #ffffff;
      border-radius: 4px;
      transform: scale(0.5);
      transform-origin: left top;
      max-width: 200%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:hover{
      cursor: pointer;
    }
    .img{
      display: block;
      width: 100px;
      height: 140px;
      object-fit: cover;
    }
    .name{
      width: 100px;
      text-align: justify;
    }
    .materialActive{
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100%;
      border-radius: 4px;
      background-color: rgba(40,184,186,0.2);
      z-index: 1;
      border: 2px solid rgba(40,184,186,0.9);
      .img2{
        display: block;
        width: 25px;
        height: 25px;
      }
    }
  }
}
.editions{
  align-items: flex-start;
}
</style>
