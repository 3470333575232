<!-- /basicOperation/editor?id=327421902479949824 -->
<template>
  <div class="page" v-loading="loading">
    <div class="page-side">
      <div class="panel">
        <div class="panel-hd">
          <div class="form-extend" style="float: right;margin-top: -4px;" v-if="sectionMode !== 'draw'">
            <el-button
              type="success"
              icon="el-icon-edit-outline"
              size="small"
              :title="type !== 'add' ? '纠错模式不能修改坐标' : '启用画坐标模式'"
              :disabled="type !== 'add'"
              @click="enterDrawMode"
            >画坐标模式</el-button>
          </div>
          图片区
        </div>
        <div class="panel-bd">
          <div class="section-menus" v-show="sectionMode === 'draw'">
            <div class="section-menus-hd">
              <el-button
                type="text"
                class="section-menus-close"
                title="退出画坐标模式"
                @click="quitDrawMode"
              >完成</el-button>
              <div>画坐标</div>
            </div>
            <el-radio-group v-model="sectionKey" class="sort-container">
              <el-radio class="section-menu" :label="0">大题</el-radio>
              <template v-if="isCombination">
                <el-radio
                  class="section-menu"
                  v-for="item in subQuestionGroup"
                  :key="item.qid"
                  :label="item.qid"
                  :style="{ order: item.order }"
                >
                  第{{ item.order }}小问
                </el-radio>
              </template>
            </el-radio-group>
          </div>

          <SectionPicker
            ref="sectionPicker"
            :lock="sectionMode !== 'draw'"
            :selectable="false"
            @change="sectionChange"
            @draw="sectionDraw"
          />

          <div class="pagination">
            <el-pagination
              small
              layout="slot, total, prev, pager, next, jumper"
              :current-page.sync="sectionData.curPage"
              :page-count="sectionData.total"
              :pager-count="5"
              @current-change="paginationChange"
            >
              <span class="total">共 {{ sectionData.total }} 页</span>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="page-main">
      <div class="panel">
        <div class="panel-hd">题目编辑区</div>
        <div class="panel-bd questionForm scrollbarStyle">
          <div class="aiTagging">
            <el-button type="default" size="small" @click="handleTagging">AI标注 (体验版)</el-button>
            <el-tooltip class="item" effect="dark" placement="bottom-end">
              <template slot="content">
                <div style="width: 280px;">AI标注(体验版)是根据题干内容而输出的答案、解析、知识点等结果，不能保证每次结果都是100%准确，结果仅供参考，最终解释权归平台所有。</div>
              </template>
              <i class="el-icon-question" style="font-size: 18px;vertical-align: text-bottom;margin-left: 8px;color: #18C2C2;"></i>
            </el-tooltip>
          </div>

          <el-form :model="formData" label-width="85px" label-position="right" label-suffix=":" size="small">
            <!--<el-form-item label="题型模式:">-->
            <!--  <el-radio-group-->
            <!--    :disabled="type === 'edit'"-->
            <!--    :value="isCombination"-->
            <!--    @input="combinationChange"-->
            <!--  >-->
            <!--    <el-radio :label="false">不包含小题</el-radio>-->
            <!--    <el-radio :label="true">包含小题</el-radio>-->
            <!--  </el-radio-group>-->
            <!--</el-form-item>-->

            <el-form-item label="题型" required>
              <el-select v-model="formData.questionType" placeholder="请选择题型">
                <el-option
                  v-for="item in QuestionTypeOptions"
                  :key="item.questionType"
                  :label="item.questionTypeName"
                  :value="item.questionType">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预估时长" v-show="false">
              <el-input-number v-model="estimatedTime" :min="1" :precision="0" :max="3600" />
              <span> 秒</span>
            </el-form-item>
            <el-form-item label="难易度" required>
              <ui-difficulty-picker  v-model="formData.degreeOfDifficulty"></ui-difficulty-picker>
            </el-form-item>
            <!--<el-form-item label="章节">-->
            <!--  <el-input :value="searchData.chapterName" readonly class="form-input" />-->
            <!--</el-form-item>-->
            <el-row type="flex">
              <el-col :span="10">
                <el-form-item label="题号" required>
                  <el-input v-model="formData.titleNumber" class="form-input" placeholder="例如 14.1" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="分值" v-if="isCombination">
                  <!--<el-input :value="totalScore" class="form-input" readonly placeholder="请设置小题分值" />-->
                  <span class="placeholder" v-if="totalScore >= 0">{{ totalScore }}</span>
                  <span class="placeholder" v-else>请设置小题分值</span>
                </el-form-item>
                <el-form-item label="分值" v-else>
                  <InputScore v-model="formData.score" />
                </el-form-item>
              </el-col>
            </el-row>

            <template v-if="isCombination">
              <el-form-item label="题目">
                <ui-editor v-model="formData.stem" @ocr="handleOCR" />
              </el-form-item>

              <el-form-item label="音频" v-if="showAudio">
                <ui-uploader accept=".mp3,.m4a" OSSClient="ALIOSS" :base-url="audioBaseUrl" :max-size="100" v-model="audioUrl" >
                  <template v-slot:default="{ scope }" >
                    <el-button type="primary" :loading="scope.loading">上传</el-button>
                  </template>
                </ui-uploader>
                <div class="uploader-audio" v-if="audioUrl">
                  <ui-audio-player :src="audioUrl" />
                  <el-button type="text" size="medium" class="audio-delete" icon="el-icon-delete" @click="removeAudio" title="删除"></el-button>
                </div>
              </el-form-item>

              <el-form-item label="听力材料" v-if="showAudio">
                <!--<el-input type="textarea" v-model="formData.resource" placeholder="请输入" />-->
                <LyricEditor v-model="formData.resource" placeholder="请输入" />
              </el-form-item>
            </template>
          </el-form>

          <!-- 小题编辑列表 -->
          <template v-if="isCombination">
            <div class="sort-container">
              <div
                class="subQuestion v-animate"
                v-for="(item, index) in activeSubQuestionGroup.filter(m => !m.delete)"
                :key="item.qid"
                :style="{ order: item.order }"
                :tag="item.qid"
              >
                <div>
                  <div class="subQuestion-menus">
                    <el-button type="text" @click="sortChange(item, 'prev')" :disabled="item.order < 2">
                      上移<i class="el-icon-upload2"></i>
                    </el-button>
                    <el-button type="text" @click="sortChange(item, 'next')" :disabled="item.order >= activeSubQuestionGroup.length">
                      下移<i class="el-icon-download"></i>
                    </el-button>
                    <el-button type="text" @click="subQuestionDelete(item, index)" :disabled="type === 'edit' && activeSubQuestionGroup.length < 2">删除</el-button>
                  </div>
                  <div class="subQuestion-title"></div>
                  <!--<div class="subQuestion-title">第{{ item.order }}小题:</div>-->
                </div>
                <QuestionForm
                  :question="item.data"
                  :isSub="true"
                  :knowledgeOptions="KnowledgeOptions"
                  :keyAccomplishmentOptions="KeyAccomplishmentOptions"
                  :order="item.order"
                  @ocr="handleOCR"
                />
              </div>
            </div>

            <!--<el-button-->
            <!--  type="default"-->
            <!--  size="small"-->
            <!--  icon="el-icon-plus"-->
            <!--  class="btn-add"-->
            <!--  @click="subQuestionAdd"-->
            <!--&gt;添加小题</el-button>-->
          </template>

          <!-- 大题内容编辑 -->
          <QuestionForm
            v-else
            :question="formData"
            :knowledgeOptions="KnowledgeOptions"
            :keyAccomplishmentOptions="KeyAccomplishmentOptions"
            @ocr="handleOCR"
          >
            <template slot="attachment">
              <el-form-item label="音频" v-if="showAudio">
                <ui-uploader accept=".mp3,.m4a" OSSClient="ALIOSS" :base-url="audioBaseUrl" :max-size="100" v-model="audioUrl" >
                  <template v-slot:default="{ scope }" >
                    <el-button size="mini" type="primary" :loading="scope.loading">上传</el-button>
                  </template>
                </ui-uploader>
                <div class="uploader-audio" v-if="audioUrl">
                  <ui-audio-player :src="audioUrl" />
                  <el-button type="text" size="medium" class="audio-delete" icon="el-icon-delete" @click="removeAudio" title="删除"></el-button>
                </div>
              </el-form-item>

              <el-form-item label="听力材料" v-if="showAudio">
                <!--<el-input type="textarea" v-model="formData.resource" placeholder="请输入" />-->
                <LyricEditor v-model="formData.resource" placeholder="请输入" />
              </el-form-item>
            </template>
          </QuestionForm>

          <!-- 新增题目/编辑组合题 可添加小题 -->
          <template v-if="type === 'add' || isCombination">
            <el-button
              type="default"
              size="small"
              icon="el-icon-plus"
              class="btn-add"
              @click="subQuestionAdd"
            >添加第{{ activeSubQuestionGroup.length + 1 }}小问</el-button>
          </template>

          <div class="form-footer">
            <!--<el-button type="primary" plain>保存</el-button>-->
            <el-button type="primary" @click="submit">保存</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 查重弹窗 -->
    <CheckRepeat ref="checkRepeat" @submit="addQuestion" />
  </div>
</template>

<script>
import { Tooltip } from 'element-ui'
import { createQuestionData, getQuestionData } from './utils'

import LyricEditor from '@/components/Lyric/Editor'

import CheckRepeat from './CheckRepeat'
import QuestionForm from './QuestionForm'
import SectionPicker from '../modules/SectionPicker'
import { getQuestionBankImage, getQuestionListByClassHourId, getSubjectAndGrade } from '@/api/branchImport'
import { getStandardTeachingMaterialId } from '@/api/question-bank'
import { materialRecordInfo } from '@/api/changeMaterial'
import { getKnowledgePointList } from '@/api/knowledge'
import { getKeyAccomplishmentList } from '@/api/keyAccomplishment'
import { questionAdd, questionDetail, checkRepeat, questionEdit, questionThemeAdd } from '@/api/recordQuestion'
import { imageToLatex } from '@/api/common'
import { aiTagging } from '@/api/ai'
import { Difficulty } from '@/config/enums/Difficulty'
import InputScore from '../modules/InputScore'

// const env = development
// teachingMaterialId

export default {
  name: 'BasicOperationEditor',
  components: {
    'el-tooltip': Tooltip,
    CheckRepeat,
    QuestionForm,
    SectionPicker,
    LyricEditor,
    InputScore,
  },
  data () {
    return {
      loading: false,
      type: 'add',
      questionList: [], // 课时题目列表
      questionDetail: null, // 当前编辑题目详情（题目列表数据不完整）

      // 编辑区
      id: null, // 题目ID
      formData: createQuestionData(), // 大题表单
      estimatedTime: undefined, // 预估时长
      audioUrl: '', // 听力题音频url
      subQuestionGroup: [], // 小题数据集合
      activeSubQuestionGroup: [],

      // 图片区
      curSectionList: [], // 当前操作选区
      sectionMode: 'pick', // draw | pick
      sectionKey: 0,
      sectionData: {
        curPage: 1, // 当前页码
        total: 0, // 总页码
        pageList: [], // 图片列表
      },

      // 编辑区选项
      QuestionTypeOptions: [],
      KnowledgeOptions: null,
      KeyAccomplishmentOptions: [],
    }
  },
  computed: {
    // 小题总分
    totalScore () {
      const list = this.activeSubQuestionGroup

      if (list.length === 0) {
        return this.formData.score
      }
      let total = 0
      for (const item of list) {
        if (item.data.score >= 0) {
          total += item.data.score
        // } else {
        //   // 存在小题分值未设置
        //   return undefined
        }
      }
      return total
    },
    searchData () {
      return this.$store.state.branchImport.searchData
    },
    // 教辅音频上传目录
    audioBaseUrl () {
      const searchData = this.$store.state.branchImport.searchData
      const mid = searchData && searchData.teachingMaterialId ? searchData.teachingMaterialId : 'common'
      return `teaching_material/audio/${mid}/`
    },
    showAudio () {
      const typeList = [
        'LISTENING_QUESTIONS', // 听力题
        'LISTENING_FILL_IN_THE_BLANKS', // 听力填空题
        'LISTENING_MATCHING', // 听力匹配题
        'HEARING_JUDGMENT', // 听力判断
        'LISTENING_RANKING', // 听力排序题
      ]
      return typeList.includes(this.formData.questionType)
    },
    // activeSubQuestionGroup () {
    //   return this.isCombination
    //     ? this.subQuestionGroup.filter(m => !m.delete)
    //     : []
    // },
    isCombination () { // 题目类型 - 是否为复合题（大小题）
      // TODO: subQuestionGroup 存在标记已删除小题（待测试）
      return this.subQuestionGroup.length > 0
    },
  },
  mounted () {
    const { params, query } = this.$route
    let questionId
    if (query.type === 'edit') {
      this.type = 'edit'
      questionId = query.questionId || params.questionId
    } else {
      this.type = 'add'
    }

    this.loading = true
    Promise.all([
      this.getMaterialRecordInfo(),
      // - 根据标准教材ID获取基础性作业信息（教辅ID、年级、科目）
      this.getSubjectAndGrade(),
      // - 根据课时id获取所有题目
      this.getQuestionListByClassHourId(),
      // - 获取题型列表
      this.loadQuestionTypeOptions(),
      // - 知识点选项
      this.loadKnowledgeOptions(),
      // - 核心素养选项
      this.loadKeyAccomplishmentOptions(),
      this.loadQuestionImage(),
    ]).finally(() => {
      this.loading = false
    }).then(() => {
      if (this.type === 'edit') {
        // 编辑
        const question = this.questionList.find(m => m.id === questionId)
        if (!question) {
          return this.$message.error('未找到指定题目')
        }

        if (question.reviseFlag && this.hasChildMaterialFlag) {
          return this.$message.error('不能编辑改版存档题目')
        }

        return this.getQuestionDetail(question.id).then(() => {
          // 1.1 初始化当前编辑题目
          this.setCurrentQuestion(this.detail)

          // 1.2 设置选区默认页码
          if (this.curSectionList.length) {
            this.sectionData.curPage = this.curSectionList[0].page || 1
          }
        })
      } else {
        // 新增 - 清空数据
        this.setEmptyQuestion()
        this.sectionData.curPage = Number(query.pageNumber || 1)
      }
    }).finally(() => {
      // 渲染指定页面
      this.paginationChange(this.sectionData.curPage)
    })
  },
  methods: {
    //#region 页面加载流程 ====================================
    // 初始化为空题目 - 即新增题目
    setEmptyQuestion () {
      // this.cacheId = 0 // 小题临时标记
      this.formData = createQuestionData()
      this.audioUrl = ''
      this.subQuestionGroup = []
      this.curSectionList = []
    },

    // 根据录题员获取上次录题教辅信息 - 获取修订版本信息，判断题目编辑权限
    getMaterialRecordInfo () {
      return materialRecordInfo().then(res => {
        if (res.code === 0) {
          this.hasChildMaterialFlag = res.data.childMaterialFlag
        } else {
          this.$message.error(res.msg)
          throw new Error('教辅信息错误')
        }
      })
    },

    // 根据标准教材ID获取基础性作业信息（教辅ID、年级、科目）
    getSubjectAndGrade () {
      const materialId = this.searchData.teachingMaterialId

      return getSubjectAndGrade(materialId).then(res => {
        if (res.code === 0) {
          if (res.data) {
            this.subject = res.data.subject
            this.grade = res.data.grade
            this.teachingMaterialId = res.data.teachingMaterialId
          } else {
            this.$message.error('未找到对应教辅')
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 根据课时id获取所有题目
    getQuestionListByClassHourId () {
      const classHourId = this.searchData.classHourId
      const materialId = this.searchData.teachingMaterialId
      return getQuestionListByClassHourId(classHourId, materialId).then(res => {
        if (res.code === 0) {
          this.questionList = res.data
        } else {
          throw new Error(res.msg)
        }
      })
    },

    getQuestionDetail (id) {
      this.loading = true
      return questionDetail(id, this.searchData.teachingMaterialId).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          this.detail = res.data
        } else {
          this.$message.error(res.msg)
          throw new Error(res.msg)
        }
      })
    },

    // 获取题型列表
    loadQuestionTypeOptions () {
      const stmId = this.searchData.editionGradeValue
      return getStandardTeachingMaterialId(stmId).then(res => {
        if (res.code === 0) {
          this.QuestionTypeOptions = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 知识点选项
    loadKnowledgeOptions () {
      // 非最后一层都不可选中
      const disableTreeData = (list) => {
        list.forEach(item => {
          const children = item.children || []
          if (children.length) {
            item.disabled = true
            disableTreeData(children)
          }
        })
      }

      return getKnowledgePointList(this.searchData.subjectId).then(res => {
        if (res.code === 0) {
          disableTreeData(res.data)
          this.KnowledgeOptions = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 核心素养选项
    loadKeyAccomplishmentOptions () {
      const subsectionId = this.searchData.subjectId
      return getKeyAccomplishmentList(subsectionId).then(res => {
        if (res.code === 0) {
          this.KeyAccomplishmentOptions = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //#endregion

    //#region 画坐标 ====================================
    // 根据大小题 Id 获取 label，list 取小题列表 this.subQuestionGroup
    getLabel (qid, list) {
      if (qid === 0) return '大题'
      const sub = list.find(m => m.qid === qid)
      if (sub && sub.order) {
        return `第${sub.order}小问`
      } else {
        return '已清除'
      }
    },

    // 页面-选区 数据
    pageCoordinateDate (pageNumber, questionList, curSectionList) {
      const result = []
      questionList.forEach(question => {
        const title = question.titleNumber || ''
        question.coordinate && question.coordinate.forEach(m => {
          if (m.page === pageNumber) {
            m.coordinate.forEach(coordinate => {
              result.push({
                label: title,
                data: {
                  question,
                  isLabel: question.isLabel,
                },
                coordinate
              })
            })
          }
        })
        // 小题选区
        if (question.isCombination && question.subtopicQuestion) {
          question.subtopicQuestion.forEach(sub => {
            sub.coordinate && sub.coordinate.forEach(m => {
              if (m.page === pageNumber) {
                m.coordinate.forEach(coordinate => {
                  result.push({
                    label: title,
                    data: {
                      question,
                      isLabel: question.isLabel,
                    },
                    coordinate
                  })
                })
              }
            })
          })
        }
      })
      return result.concat(curSectionList.filter(m => m.page === pageNumber))
    },

    // 选区变更
    sectionChange (list) {
      console.log('--- sectionChange', list)
      if (this.sectionMode === 'pick') {
        const s = list.find(m => m.data.ocr)
        if (s && s.data.qid >= 0) {
          this.curSectionList.forEach(m => {
            m.data.ocr = m.data.qid === s.data.qid
          })
        }
      }
      if (this.sectionMode === 'draw') {
        // 1. 画坐标模式 - 变更选区
        let keyStart = this.curSectionList.reduce((prev, c) => Math.max(prev, c.data.key), 0) + 1
        this.curSectionList = this.curSectionList
          // 1.1 删除当前页旧坐标
          .filter(sec => sec.page !== this.sectionData.curPage)
          // 1.2 合并画板active选区
          .concat(list.map(m => {
            // 当前题目ID
            if (m.data.ocr) {
              this.sectionKey = m.data.qid
            }
            const key = m.key ? m.key : keyStart++
            return {
              active: true,
              data: {
                key,
                qid: m.data.qid,
                ocr: m.data.ocr,
                label: m.data.label,
              },
              page: this.sectionData.curPage,
              coordinate: {
                x_min: +m.point[0].x.toFixed(2),
                y_min: +m.point[0].y.toFixed(2),
                x_max: +m.point[2].x.toFixed(2),
                y_max: +m.point[2].y.toFixed(2),
              }
            }
          }))
      } else {
        // // 2. 题目选择模式 - 多题纠错操作，用于选择题目
        // const shapeList = this.$refs.sectionPicker.drawTool.regionCanvas.shapeList
        // const idx = shapeList.findIndex(item => item.isActive)
        // if (idx >= 0) {
        //   const question = shapeList[idx].$data
        //   if (question.reviseFlag && this.hasChildMaterialFlag) {
        //     return this.$message.error('不能编辑改版存档题目')
        //   }
        //   if (this.formData.questionId !== question.id) {
        //     this.getQuestionDetail(question.id).then(() => {
        //       this.setCurrentQuestion(this.detail)
        //     })
        //   }
        // }
      }
    },

    // 新增选区
    sectionDraw (shape) {
      console.log('--- sectionDraw', shape)
      if (shape.$data) {
        const qid = this.sectionKey
        const label = this.getLabel(qid, this.subQuestionGroup)
        shape.$data.qid = qid
        shape.$data.label = label
        shape.$data.isLabel = true
        shape.tipText = label
      }
    },

    // 更新选区
    updateSectionData (curPage, clearOCRState = false) {
      const total = this.sectionData.total
      if (!total) return // 图片列表为空
      const idx = curPage > total ? total : curPage
      const pageInfo = this.sectionData.pageList[idx - 1]

      const questionList = this.questionList.filter(m => m.id !== this.formData.questionId)
      // this.curSectionList.forEach(m => { m.data.ocr = false })
      this.curSectionList.forEach(m => {
        // 重置OCR选区
        if (clearOCRState) m.data.ocr = false
        // 更新 label
        const label = this.getLabel(m.data.qid, this.subQuestionGroup)
        m.data.label = label
        m.data.isLabel = true
        m.label = label
      })
      const sections = this.pageCoordinateDate(pageInfo.page, questionList, this.curSectionList)
      this.$nextTick(() => {
        this.$refs.sectionPicker.pageRender(pageInfo.page, pageInfo.questionImage, sections)
      })
    },

    // 翻页
    paginationChange (n) {
      this.updateSectionData(n, true)
    },

    // 获取图片列表
    loadQuestionImage () {
      const teachingMaterialId = this.searchData.teachingMaterialId
      const standardTeachingMaterialId = this.searchData.editionGradeValue

      this.imgLoading = true
      return getQuestionBankImage(teachingMaterialId, standardTeachingMaterialId).finally(() => {
        this.imgLoading = false
      }).then(res => {
        if (res.code === 0) {
          this.sectionData.pageList = res.data ? res.data.sort((a, b) => a.page - b.page) : []
          this.sectionData.total = this.sectionData.pageList.length
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        // 图片列表请求异常 - 仅提示错误信息，不影响主流程
        this.$message.error(e.message)
      })
    },
    //#endregion

    // 同步并更新 小题
    updateActiveGroup () {
      let order = 0
      this.activeSubQuestionGroup = this.isCombination
        ? this.subQuestionGroup.filter(m => {
          // 排除已删除（需提交后端的小题删除操作）
          if (m.delete) return false
          order += 1
          m.order = order
          return true
        })
        : []
    },

    // 初始化当前编辑题目
    setCurrentQuestion (origin) {
      let sectionKey = 0 // 选区Key (自增)
      let cacheId = 0 // 小题临时标记 (自增)

      const question = JSON.parse(JSON.stringify(origin))

      const isCombination = question.isCombination
      this.id = question.questionId
      this.formData = question
      this.audioUrl = question.audioResource && question.audioResource.url
        ? question.audioResource.url : ''

      // 当前选区 - 大题部分
      const curList = []
      const title = question.titleNumber || ''
      question.coordinate.forEach(g => {
        g.coordinate.forEach(m => {
          curList.push({
            active: true,
            data: {
              // question,
              key: sectionKey++,
              qid: cacheId,
              // questionId: question.questionId,
            },
            page: g.page,
            label: title,
            coordinate: m
          })
        })
      })

      // 创建小题数据
      this.subQuestionGroup = !isCombination
        ? []
        : question.subtopicQuestion.sort((a, b) => a.sort - b.sort).map(sq => {
          cacheId += 1
          // 当前选区 - 小题部分
          sq.coordinate && sq.coordinate.forEach(g => {
            g.coordinate.forEach(m => {
              curList.push({
                active: true,
                data: {
                  // question,
                  key: sectionKey++,
                  qid: cacheId,
                },
                page: g.page,
                label: title + '-' + cacheId,
                coordinate: m
              })
            })
          })

          return {
            qid: cacheId,
            legacy: true, // 原始题，必须标记删除
            delete: false, // 删除标记
            order: 0,
            data: sq,
          }
        })
      this.curSectionList = curList
      this.updateActiveGroup()
    },

    // 切换题型模式 - 大小题
    combinationChange (val) {
      this.isCombination = val
      if (val) {
        if (!this.subQuestionGroup) {
          this.subQuestionGroup = []
        }
        if (this.subQuestionGroup.length === 0) {
          this.subQuestionAdd()
        }
      }
    },

    // 添加小题
    subQuestionAdd () {
      // maxId: 用于生成临时id，处理v-for缓存
      const maxId = this.subQuestionGroup
        .reduce((max, group) => Math.max(max, group.qid), 0)
      this.subQuestionGroup.push({
        qid: maxId + 1,
        delete: false,
        legacy: false, // 临时（未提交）小题
        order: 0,
        data: createQuestionData(true),
      })
      this.updateActiveGroup()
    },

    // 删除小题
    subQuestionDelete (item, index) {
      const message = (<div>
        <div>确定删除该试题吗？</div>
        <p style="font-size: 12px;color: #ccc;">一旦删除，不可撤回</p>
      </div>)
      this.$confirm(message).then(() => {
        if (item.legacy) {
          item.delete = true
        } else {
          this.subQuestionGroup.splice(index, 1)
        }
        this.updateActiveGroup()

        if (this.sectionKey === index + 1) {
          // 重置当前画坐标小题
          this.sectionKey = 0
        }
        // 删除小题坐标
        this.curSectionList = this.curSectionList.filter(m => m.data.qid !== item.qid)
        this.updateSectionData(this.sectionData.curPage)
      })
    },

    // 表单提交 - 保存
    submit () {
      this.getSectionInfo()

      const params = this.formData

      if (!params.questionType) {
        return this.$message.warning('请选择题型')
      }
      if (!params.degreeOfDifficulty) {
        return this.$message.warning('请选择难易度')
      }
      if (!params.titleNumber) {
        return this.$message.warning('请输入题号')
      }

      // 听力题增加听力资源
      params.audioResource = {
        url: this.audioUrl
      }

      // 预估时长
      if (this.estimatedTime) {
        params.estimatedTime = this.estimatedTime
      }

      const stemList = [params.stem]
      if (this.isCombination) {
        const list = this.activeSubQuestionGroup
        for (let idx = 0; idx < list.length; idx++) {
          const { stem, score } = list[idx].data
          // 组合题：大题干非必填，验证小题题干
          if (!stem) {
            return this.$message.warning(`请输入第${idx + 1}小问`)
          }
          stemList.push(stem)
          // 小题分值验证
          if (this.totalScore > 0 && !(score > 0)) {
            return this.$message.warning(`第${idx + 1}小问未设置分值`)
          }
        }
      } else if (!params.stem) {
        // 独立题：大题干必填
        return this.$message.warning('请输入题目')
      }

      // - 编辑
      if (this.type === 'edit') {
        return this.editQuestion()
      }

      // - 新增
      this.loading = true
      checkRepeat({
        stem: stemList.join(' '),
        // subject: this.subject,
        // grade: this.grade,
        subsectionId: this.searchData.subjectId,
      }).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length) {
            this.$refs.checkRepeat.show(stemList, res.data)
          } else {
            this.addQuestion()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 提交题目画坐标数据测试
    getSectionInfo () {
      const coData = {}
      this.curSectionList.forEach((m) => {
        const page = m.page
        if (!coData[page]) {
          coData[page] = { page, coordinate: [] }
        }
        coData[page].coordinate.push(m.coordinate)
      })
      console.log('--- getSectionInfo', coData)
    },

    // 保存 - 编辑/纠错
    editQuestion () {
      // const coData = {}
      // this.curSectionList.forEach((m, idx) => {
      //   const page = m.page
      //   if (!coData[page]) {
      //     coData[page] = { page, coordinate: [] }
      //   }
      //   coData[page].coordinate.push(m.coordinate)
      // })

      // 小题数据
      const subtopicQuestion = []
      if (this.isCombination) {
        // return console.log('--- 编辑题目：', params)
        this.subQuestionGroup.forEach((g) => {
          if (g.delete) {
            // 删除已有小题
            subtopicQuestion.push({
              questionId: g.data.questionId,
              isDelete: true,
            })
          } else {
            const d = {
              coordinate: this.getSectionsByQid(g.qid), // 小题坐标
              chapter: [{ chapterId: this.searchData.chapterId }],
              isDelete: false,
              sort: g.order,
            }
            if (g.legacy) { d.questionId = g.data.questionId }
            subtopicQuestion.push(getQuestionData(g.data, true, d))
          }
        })
      }

      // 大题数据
      const params = getQuestionData(this.formData, false, {
        titleSource: 'TEACHING_MATERIAL',
        isCombination: this.isCombination,

        questionId: this.formData.questionId,
        subsectionId: this.searchData.subjectId,
        subject: this.subject,
        grade: this.grade,
        chapter: [{ chapterId: this.searchData.chapterId }],
        teachingMaterialId: this.teachingMaterialId,
        classHourId: this.searchData.classHourId,
        coordinateInfo: this.getSectionsByQid(0),
        subtopicQuestion,
      })

      this.loading = true
      questionEdit(params).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          this.$confirm('返回题目列表', '完成').then(() => {
            this.$router.push({
              name: 'BatchImport'
            })
          }).catch(() => {
            const questionId = res.data || this.id
            if (questionId) {
              // 1. 改编教辅题目编辑会创建新题目
              // 2. 当前题目状态重置（例如：删除小题操作不能再次提交）
              location.href = `${location.pathname}?type=edit&questionId=${questionId}`
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    /**
     * 保存 - 新增题目
     * @param {String?} repeatId - 查重确认相似题目ID
     */
    addQuestion (repeatId) {
      // 2. 组合题
      if (this.isCombination) return this.addCombinationQuestion(repeatId)

      const coordinate = this.getSectionsByQid(0)
      const pageNumber = coordinate.length ? coordinate[0].page : null

      const params = getQuestionData(this.formData, false, {
        // questionType: this.questionTypeValue,
        // degreeOfDifficulty: this.diffValue,
        // titleNumber: this.questionNum,
        // stem: this.tgContent,
        // answer: this.daContent,
        // analysis: this.jxContent,
        // knowledge: this.questionData.knowledge,
        // keyAccomplishment: this.questionData.keyAccomplishment,

        coordinate,
        pageNumber,

        subsectionId: this.searchData.subjectId,
        subject: this.subject,
        teachingMaterialId: this.teachingMaterialId,
        chapter: [{ chapterId: this.searchData.chapterId }],
        classHourId: this.searchData.classHourId,
        // audioResource: { url: 'http://www.baidu.com/1.mp3' },
        // videoResource: { url: 'http://www.baidu.com/1.mp4' }
      })
      if (repeatId) {
        params.questionId = repeatId
      }
      this.$confirm('是否确认保存当前操作?').then(() => {
        // 1. 独立题
        return questionAdd(params)
      }).then(res => {
        if (res.code === 0) {
          this.$confirm('是否继续新增', '完成', {
            confirmButtonText: '继续新增',
            cancelButtonText: '返回列表',
            center: true
          }).then(
            () => {
              this.setEmptyQuestion()
              this.getQuestionListByClassHourId().then(() => {
                // 更新选区
                this.paginationChange(this.sectionData.curPage)
              })
            },
            () => { this.$router.push('/batchImport') }
          )
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 根据临时题目ID，获取选区列表
    getSectionsByQid (qid) {
      const coData = {}
      this.curSectionList.forEach(m => {
        if (qid !== (m.data && m.data.qid) || 0) return
        const page = m.page
        if (!coData[page]) {
          coData[page] = { page, coordinate: [] }
        }
        coData[page].coordinate.push(m.coordinate)
      })
      return Object.values(coData)
    },

    // 新增组合题
    addCombinationQuestion (repeatId) {
      // 小题数据
      const subtopicQuestion = []
      this.subQuestionGroup.forEach((g) => {
        const d = {
          coordinate: this.getSectionsByQid(g.qid), // 小题坐标
          chapter: [this.searchData.chapterId],
          knowledge: g.data.knowledge.map(m => m.knowledgeId),
          keyAccomplishment: g.data.keyAccomplishment.map(m => m.keyAccomplishmentId),
          sort: g.order,
        }
        subtopicQuestion.push(getQuestionData(g.data, true, d))
      })

      const params = {
        titleSource: 'TEACHING_MATERIAL',
        themeStem: this.formData.stem,
        questionType: this.formData.questionType,
        degreeOfDifficulty: this.formData.degreeOfDifficulty,
        titleNumber: this.formData.titleNumber,
        audioResource: { url: this.audioUrl },
        resource: this.formData.resource,
        videoResource: {},
        subsectionId: this.searchData.subjectId,
        subject: this.subject,
        grade: this.grade,
        teachingMaterialId: this.teachingMaterialId,
        classHourId: this.searchData.classHourId,
        coordinate: this.getSectionsByQid(0),
        subtopicQuestion, // 小题
      }
      if (repeatId) {
        params.questionId = repeatId
      }
      this.$confirm('是否确认保存当前操作?').then(
        () => questionThemeAdd(params)
      ).then(res => {
        if (res.code === 0) {
          this.$confirm('是否继续新增', '完成', {
            confirmButtonText: '继续新增',
            cancelButtonText: '返回列表',
            center: true
          }).then(
            () => {
              this.setEmptyQuestion()
              this.updateActiveGroup()
              this.getQuestionListByClassHourId().then(() => {
                // 更新选区
                this.paginationChange(this.sectionData.curPage)
              })
            },
            () => { this.$router.push('/batchImport') }
          )
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    /**
     * OCR: 获取当前选区详情 - 用于编辑器OCR请求
     * @param {Function} callback - Quill 组件回调方法
     */
    handleOCR (callback) {
      // const sections = []
      // this.curSectionList.forEach(m => {
      //   const page = this.sectionData.pageList.find(n => n.page === m.page)
      //   if (page && page.questionImage) {
      //     sections.push(Object.assign({
      //       imageUrl: page.questionImage
      //     }, m.coordinate))
      //   }
      // })
      // if (sections.length === 0) {
      //   this.$message.error('请添加题目选区')
      //   return
      // }
      // const shape = this.$refs.sectionPicker.drawTool.regionCanvas.shapeList.find(m = m.data && m.data.ocr)
      const curSection = this.curSectionList.length === 1
        ? this.curSectionList[0]
        : this.curSectionList.find(m => m.data && m.data.ocr)
      if (!curSection) {
        this.$message.error('请选择OCR识别选区')
        return
      }
      const page = this.sectionData.pageList.find(n => n.page === curSection.page)
      if (!page) {
        this.$message.error('扫描图出错')
        return
      }
      const sections = [
        Object.assign({
          imageUrl: page.questionImage
        }, curSection.coordinate)
      ]

      this.loading = true
      imageToLatex(sections).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          res.data && callback && callback(res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 显示排序操作
    sortChange (subtopic, direction) {
      // this.activeSubQuestionGroup
      const curOrder = subtopic.order
      const tarOrder = direction === 'next' ? curOrder + 1 : curOrder - 1
      const tar = this.activeSubQuestionGroup.find(m => m.order === tarOrder)
      console.log(subtopic)
      if (tar) {
        this.loading = true
        const wrapperOrigin = document.querySelector(`.subQuestion[tag="${subtopic.qid}"]`)
        const wrapperTar = document.querySelector(`.subQuestion[tag="${tar.qid}"]`)
        const actionOrigin = direction === 'next' ? 'v-ani-fadeDown' : 'v-ani-fadeUp'
        const actionTar = direction === 'next' ? 'v-ani-fadeUp' : 'v-ani-fadeDown'

        wrapperOrigin.classList.add(actionOrigin)
        wrapperTar.classList.add(actionTar)
        setTimeout(() => {
          tar.order = curOrder
          subtopic.order = tarOrder
          wrapperOrigin.classList.remove(actionOrigin)
          wrapperTar.classList.remove(actionTar)

          this.updateSectionData(this.sectionData.curPage)
          this.loading = false
        }, 300)
      }
    },

    // 画坐标模式
    enterDrawMode () {
      this.sectionKey = 0
      this.sectionMode = 'draw'
    },
    // 退出画坐标模式
    quitDrawMode () {
      this.sectionMode = 'pick'
    },

    // 删除音频
    removeAudio () {
      this.audioUrl = ''
    },

    // AI标注
    handleTagging () {
      const subsectionId = this.searchData.subjectId
      if (!this.formData.stem) {
        this.$message.error('请输入题目用以智能分析')
        return
      }

      // 题干组合成接口参数
      const stemTransform = () => {
        if (!this.isCombination) return this.formData.stem
        const list = new Array(this.activeSubQuestionGroup.length)
        this.activeSubQuestionGroup.forEach(m => {
          list[m.order - 1] = ` ${m.order}、${m.data.stem}`
        })
        return `${this.formData.stem} ${list.join('')}`
      }

      // 答案&解析 拆分大小题
      const parseQuestionContent = (content) => {
        const data = []
        const total = content.length
        const startStr = '#1、'
        if (content.startsWith(startStr)) {
          let i = 1
          let start = startStr.length
          while (start < total) {
            i += 1 // 第一小题截止`#2、`
            const endTag = `#${i}、`
            const next = content.indexOf(endTag, start)
            const end = next < 0 ? total : next
            data.push(content.substring(start, end))
            start = end + endTag.length
          }
        }
        return data
      }

      const data = {
        isCombination: this.isCombination,
        image: [],
        subsectionId,
        stem: stemTransform(),
      }
      console.log(data)
      this.loading = true
      return aiTagging(data)
        .finally(() => { this.loading = false })
        .then(res => {
          if (res.code === 0) {
            const data = res.data

            // 时长 data.time: '3分4秒'
            if (data.time) {
              const match = data.time.trim().match(/((\d+)分)?((\d+)秒)?/)
              this.estimatedTime = (match[2] ? parseInt(match[2]) : 0) * 60 + (match[4] ? parseInt(match[4]) : 0)
            }

            // 难易度 （以 0-4 表示）
            if (data.difficulty >= 0 && data.difficulty < Difficulty.length) {
              this.formData.degreeOfDifficulty = Difficulty[data.difficulty].value
            }

            // 知识点: knowledge_path 代表单个知识点的层级列表，实际仅一个选中项
            if (data.knowledge_path && data.knowledge_path.length) {
              const knowledge = [{ knowledgeId: data.knowledge_path[0] }]
              if (this.isCombination) {
                this.activeSubQuestionGroup.forEach(item => {
                  item.data = {
                    ...item.data,
                    knowledge,
                  }
                })
              } else {
                this.formData = {
                  ...this.formData,
                  knowledge,
                }
              }
            }

            // 核心素养
            if (data.literacy && data.literacy.length) {
              const keyAccomplishment = data.literacy.map(m => ({
                keyAccomplishmentId: m
              }))
              if (this.isCombination) {
                this.activeSubQuestionGroup.forEach(item => {
                  item.data.keyAccomplishment = [...keyAccomplishment]
                })
              } else {
                this.formData.keyAccomplishment = keyAccomplishment
              }
            }

            // 答案&解析
            if (this.isCombination) {
              if (data.answer) {
                const answerList = parseQuestionContent(data.answer)
                this.activeSubQuestionGroup.forEach((item) => {
                  const value = answerList[item.order - 1]
                  if (!item.data.answer) {
                    if (value) {
                      item.data.answer = value
                    }
                  }
                })
              }
              if (data.analysis) {
                const analysisList = parseQuestionContent(data.analysis)
                this.activeSubQuestionGroup.forEach((item) => {
                  const value = analysisList[item.order - 1]
                  if (value) {
                    item.data.analysis = value
                  }
                })
              }
            } else {
              if (data.answer) {
                if (!this.formData.answer) {
                  this.formData.answer = data.answer
                }
              }
              if (data.analysis) {
                this.formData.analysis = data.analysis
              }
            }
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
$PanelSpace: 10px;
$MainWidth: 210mm;

.page {
  margin: $PanelSpace $PanelSpace 0;
  display: flex;

  &-side {
     flex: 1;
     position: relative;
   }

  &-main {
     box-sizing: border-box;
     width: $MainWidth;
     margin-left: $PanelSpace;
  }
}

.panel {
  background: #fff;

  &-hd {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    background: rgba(24, 194, 194, .2);
  }

  &-bd {
    padding: 10px;
    height: calc(100vh - 140px);
  }
}

// 画坐标组件
.SectionPicker {
  height: calc(100vh - 180px);
}

// 分页
.pagination {
  margin-top: 15px;
  text-align: center;

  .total {
    font-size: 12px;
    font-weight: normal;
  }
}

// 小题容器 - 排序
.sort-container {
  display: flex;
  flex-direction: column;
}
// 小题
.subQuestion {
  position: relative;
  margin-bottom: 10px;
  padding: 0 8px;
  border: 1px solid #EEE;
  border-radius: 5px;
  background: #fff;

  &-title {
    font-weight: bold;
    height: 50px;
    line-height: 50px;
  }

  &-menus {
    float: right;
    margin-top: 5px;
  }

  .sort-menu {
    margin-right: 8px;

    i {
      cursor: pointer;
    }
  }
}

// 表单样式
/deep/ .questionForm {
  .el-form-item {
    margin-bottom: 12px;
  }

  // 输入框长度 - 题号...
  .form-input {
    width: 215px;
  }

  // 添加小题按钮
  .btn-add {
    width: 200px;
    color: #18C2C2;
  }

  // 提交按钮
  .form-footer {
    margin: 30px;
    text-align: center;

    .el-button {
      width: 250px;
    }
  }
}

// 大小题画坐标
.section-menus {
  position: absolute;
  z-index: 9;
  right: 0;
  width: 150px;
  background: #FFF;
  border: 1px solid #EEE;
  border-left: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, .5);
}
.section-menus-hd {
  padding: 0 10px;
  line-height: 40px;
}
.section-menus-close {
  float: right;
}
.section-menu {
  margin: 0;
  padding: 12px;
  display: block;

  &.is-checked {
    background: #F6F6F6;
  }
}

.uploader-audio {
  display: inline-block;
  margin-left: 1em;
  vertical-align: middle;

  audio {
    height: 40px;
    vertical-align: middle;
  }
}
.audio-delete {
  margin-left: 5px;
  padding-left: 3px;
  padding-right: 3px;
  color: #666;
}

.aiTagging {
  position: relative;
  z-index: 50;
  text-align: right;
  height: 0;
}

.placeholder {
  color: #bbb;
}
</style>
