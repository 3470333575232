<template>
  <div class="container" v-loading.fullscreen.lock="loading">
    <div class="aside layout-col" :class="{ fold: asideFold}">
      <div class="trigger-btn" @click="triggerFold"></div>
      <div class="aside-container">
        <MaterialInfo ref="MaterialInfo" @changeLesson="changeLesson" />
      </div>
    </div>

    <div class="left layout-col" v-if="classHourData">
      <div class="header-title">
        <span>课时：{{ classHourData.name }}（共{{ classHourData.totalCount || 0 }}道)</span>
        <!--<span>课时：{{ lessonInfo }}（共{{ questionList.length || 0 }}道)</span>-->
      </div>
      <div class="question-list">
        <question-stem
          class="qs"
          v-for="(item, index) in questionList"
          :key="index"
          :value="item"
          :selected="curIndex === index"
          :selectedQuestionId.sync="subtopicId"
          @change="changeSubtopic"
          @select="selectQuestion(item, index)"
          @pageChange="pageChange"
        />
        <div class="nomoredata">没有更多数据了~</div>
      </div>
    </div>
    <div class="left layout-col" v-else>
      <el-empty description="请选择章节课时" />
    </div>

    <div class="right layout-col">
      <div class="header-title-btn">
        <div> </div>
        <div>
          <el-button
            size="small"
            class="page-back"
            @click="pageBack"
          >
            返回
          </el-button>
          <el-button size="small" type="warn" @click="handClear" :disabled="!areaList.length">清除课时全部坐标</el-button>
          <el-button size="small" type="primary" @click="handSave">保存课时全部坐标</el-button>
        </div>
      </div>
      <div class="imgArea">
        <SectionPicker
          class="imgBox"
          ref="sectionPicker"
          :lock="false"
          :selectable="false"
          @change="sectionChange"
          @draw="sectionDraw"
        />
        <div class="pageNumBox">
          <ui-pagination
            @current-change="getPageNumUrl"
            :current-page.sync="pageCurrent"
            :pager-count="5"
            :page-list="paginationOption"
            small
            layout="->, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInfo from './modules/material-info/material-info.vue'
import questionStem from './modules/questionStem.vue'
import SectionPicker from './modules/SectionPicker'
import { getQuestionBankImage, getQuestionListByClassHourId } from '@/api/branchImport.js'
import { questionSaveCoordinate } from '@/api/recordQuestion'

export default {
  components: {
    MaterialInfo,
    questionStem,
    SectionPicker
  },
  data () {
    return {
      loading: false,
      curIndex: undefined, // 当前选中题目index
      subtopicId: undefined, // 当前选中题目ID（区分大小题)
      subtopicIndex: 0, // 小题序号（0：大题）

      areaKey: 0, // 坐标Key
      areaList: [], // 坐标区域列表
      questionList: [], // 课时内题目列表
      pageList: [], // 教材页面列表
      classHourData: null, // 课时信息

      pageCurrent: 1, // 当前操作页页码
      curPage: null, // 当前操作页

      asideFold: false, // 收起侧边栏
    }
  },
  computed: {
    paginationOption () {
      return this.pageList.map(m => ({
        no: m.page,
        url: m.questionImage
      }))
    }
  },
  mounted () {
    this.searchData = this.$store.state.branchImport.searchData
    // const stmid = this.searchData.editionGradeValue
    // const classHourIds = this.searchData.classHourId
    // this.classHourName = this.searchData.classHourName

    return this.$refs.MaterialInfo.show({
      standardMaterialId: this.searchData.editionGradeValue,
      materialId: this.searchData.teachingMaterialId,
      classHourId: this.searchData.classHourId,
    })

    // Promise.all([
    //   this.getQuestionListByClassHourId(classHourIds, this.searchData.teachingMaterialId),
    //   this.getQuestionBankImage(this.searchData.teachingMaterialId, stmid)
    // ]).then(() => {
    //   if (this.pageList.length) {
    //     // this.$refs.sectionPicker.init(true)
    //     const params = this.$route.params
    //
    //     let questionIdx = 0
    //     if (params.questionId) {
    //       questionIdx = this.questionList.findIndex(item => item.id === params.questionId)
    //       questionIdx < 0 && (questionIdx = 0)
    //     }
    //     const question = this.questionList[questionIdx]
    //
    //     let pageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
    //     if (question && question.coordinate && question.coordinate.length) {
    //       pageNumber = question.coordinate[0].page
    //     }
    //     const pageIdx = this.pageList.findIndex(item => item.page === pageNumber)
    //     this.curPage = {
    //       no: this.pageList[pageIdx < 0 ? 0 : pageIdx].page,
    //       url: this.pageList[pageIdx < 0 ? 0 : pageIdx].questionImage
    //     }
    //     if (question) {
    //       this.selectQuestion(question, questionIdx)
    //     } else {
    //       this.getPageNumUrl(this.curPage)
    //     }
    //   }
    // })
  },
  methods: {
    // 选区变更
    sectionChange (list) {
      console.log('---- sectionChange', list)
      if (this.curIndex === undefined) {
        // this.curSectionList = []
        this.getPageNumUrl(this.curPage)
        return this.$message.error('请先选择题目')
      }
      this.areaList = this.areaList
        .filter(m => !m.active || (m.page !== this.curPage.no)) // 清除旧坐标(当前页且可变更)
        .concat(list.map(m => ({ // 添加新坐标
          active: true,
          coordinate: this.handlerPosition(m.point),
          data: m.data,
          label: m.data.subtopicIdx === 0 ? '大题' : `第${m.data.subtopicIdx}小问`,
          page: this.curPage.no,
        })))
      console.log('---- sectionChange finish', this.areaList)
    },
    // 新增选区
    sectionDraw (shape) {
      console.log('--- sectionDraw', shape)

      if (this.curIndex >= 0) {
        const question = this.questionList[this.curIndex]
        if (shape.$data) {
          const subtopicIdx = this.getQuestionSort(question, this.subtopicId)
          shape.tipText = subtopicIdx === 0 ? '大题' : `第${subtopicIdx}小问`
          shape.$data = {
            isLabel: (subtopicIdx === 0 ? question : question.subtopicQuestion[subtopicIdx - 1]).isLabel,
            key: this.areaKey++,
            qid: this.subtopicId,
            question,
            questionId: question.id,
            subtopicIdx,
          }
        }
      }
    },

    // 当前题目标记页 => 事件冒泡：刷新当前页
    pageChange (pageNum) {
      const page = this.pageList.find(m => m.page === pageNum)
      if (page) {
        this.getPageNumUrl({
          no: page.page,
          url: page.questionImage
        })
      }
    },

    // 选择题目
    selectQuestion (question, index) {
      this.curIndex = index

      // 默认选择大题
      this.subtopicId = question.id
      this.subtopicIndex = 0

      this.areaList.forEach(m => {
        if (m.data.questionId === this.subtopicId) {
          // Object.assign(m, {
          //   active: true,
          //   label: m.data.subtopicIdx > 0 ? `第${m.data.subtopicIdx}小问` : '大题'
          // })
          const active = m.data.subtopicIdx === 0 // 默认激活大题选区
          m.active = active
          m.label = active ? '大题' : `第${m.data.subtopicIdx}小问`
        } else {
          m.active = false
          m.label = m.data.question.titleNumber || ''
        }
      })
      this.getPageNumUrl(this.curPage)
    },

    // 选择大小题
    changeSubtopic (qid) {
      console.log('--- changeSubtopic')
      const question = this.questionList[this.curIndex || 0]
      this.subtopicIndex = this.getQuestionSort(question, this.subtopicId)
      this.areaList.forEach(m => {
        m.active = m.data.qid === qid
      })
      this.getPageNumUrl(this.curPage)
    },

    // 根据题目Id获取组合题序号 (大题：0，小题：1-n)
    getQuestionSort (question, qid) {
      if (question.isCombination && question.subtopicQuestion) {
        const idx = question.subtopicQuestion.findIndex(m => m.questionId === qid)
        if (idx >= 0) {
          return idx + 1
        }
      }
      return 0
    },

    // 根据题目ID获取坐标
    getQuestionCoordinateInfo (sectionList, qid) {
      const m = {}
      sectionList.forEach(section => {
        const page = section.page
        if (section.data.qid === qid && page) {
          if (!m[page]) { m[page] = [] }
          m[page].push(section.coordinate)
        }
      })
      return Object.keys(m).map(page => ({
        page: Number(page),
        coordinate: m[page]
      }))
    },

    // 清除课时全部坐标
    handClear () {
      this.$confirm(
        '<div style="color: #FA6400;"><i class="el-icon-warning"></i> 一旦确定清除不可撤回，请你知晓！</div>',
        '确定清除当前课时全部坐标吗？',
        { dangerouslyUseHTMLString: true }
      ).then(() => {
        this.areaList = []
        this.getPageNumUrl(this.curPage)
        this.saveCoordinate()
      })
    },

    // 保存
    handSave () {
      // if (this.curIndex === undefined) {
      //   return this.$message.error('请修改题目选区后再保存数据')
      // }
      // if (this.curSectionList.length === 0) {
      //   return this.$message.error('本题没有指定选区')
      // }
      const msg = this.areaList.length === 0
        ? '本课时未指定选区，确认是否保存'
        : '是否确认保存当前操作?'
      this.$confirm(msg, '提示')
        .then(this.saveCoordinate)
    },

    saveCoordinate () {
      const params = []
      for (const question of this.questionList) {
        const item = {
          questionId: question.id,
          teachingMaterialId: this.searchData.teachingMaterialId,
          coordinateInfo: this.getQuestionCoordinateInfo(this.areaList, question.id)
        }
        // 小题坐标
        if (question.isCombination) {
          item.subtopicCoordinates = question.subtopicQuestion.map(sub => {
            return {
              questionId: sub.questionId,
              coordinateInfo: this.getQuestionCoordinateInfo(this.areaList, sub.questionId)
            }
          })
        }
        params.push(item)
      }

      this.loading = true
      return questionSaveCoordinate(params).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          for (const paramItem of params) {
            const question = this.questionList.find(m => m.id === paramItem.questionId)
            if (question) {
              this.setQuestionCoordinate(question, paramItem)
            }
          }
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 当前选区保存到题目中
    setQuestionCoordinate (question, coordinateData) {
      question.coordinate = coordinateData.coordinateInfo
      if (coordinateData.subtopicCoordinates) {
        coordinateData.subtopicCoordinates.forEach(m => {
          question.subtopicQuestion.find(sub => {
            if (sub.questionId === m.questionId) {
              sub.coordinate = m.coordinateInfo
              return true
            }
          })
        })
      }
    },

    // 翻页事件
    getPageNumUrl (pageInfo) {
      const { no, url } = pageInfo
      this.pageCurrent = no
      this.curPage = pageInfo
      // 当前页选区
      const sections = this.areaList.filter(m => m.page === no)
      console.log('--------- 渲染选区：', no, url, sections)
      this.$refs.sectionPicker.pageRender(no, url, sections)
    },

    // 根据课时id获取所有题目
    getQuestionListByClassHourId (classHourId, materialId) {
      this.loading = true
      return getQuestionListByClassHourId(classHourId, materialId).then(res => {
        if (res.code === 0) {
          this.curIndex = undefined
          this.subtopicId = undefined
          this.subtopicIndex = 0
          this.areaKey = 0
          this.questionList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      }).finally(() => {
        this.loading = false
      })
    },

    // 处理坐标数据
    handlerPosition (point) {
      return {
        x_min: +point[0].x.toFixed(2),
        y_min: +point[0].y.toFixed(2),
        x_max: +point[2].x.toFixed(2),
        y_max: +point[2].y.toFixed(2)
      }
    },

    // 获取图片列表
    getQuestionBankImage (teachingMaterialId, standardTeachingMaterialId) {
      this.loading = true
      return getQuestionBankImage(teachingMaterialId, standardTeachingMaterialId).then(res => {
        if (res.code === 0) {
          this.pageList = res.data.sort((a, b) => a.page - b.page)
          // if (this.pageList && this.pageList.length) {
          //   this.getAreaImage(this.pageList[0].questionImage)
          // }
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 切换课时
    changeLesson (data) {
      const {
        standardMaterialId,
        materialId,
        // chapterId,
        classHourId,
        classHourData,
      } = data
      this.classHourData = classHourData

      this.questionList = []
      this.areaList = []
      if (!materialId) {
        this.pageList = []
      } else {
        const loadImg = this.getQuestionBankImage(materialId, standardMaterialId)

        if (classHourId) {
          // 选中课时 加载题目
          this.getQuestionListByClassHourId(classHourId, materialId)
            .then(() => loadImg)
            .then(() => {
              // 创建坐标区域列表
              this.areaList = this.initAreaList(this.questionList)
              // 页面图片跳转
              const pageId = classHourData.pageList?.[0]
              if (pageId) {
                this.pageChange(pageId)
              }
            })
        }
      }
    },

    // 坐标区域集合初始化
    initAreaList (questionList) {
      const createAreaData = (rootQuestion, subtopicIdx, questionId, isLabel, coordinateData, target = []) => {
        coordinateData && coordinateData.forEach(({ page, coordinate }) => {
          coordinate && coordinate.forEach(coordinateItem => {
            target.push({
              active: false,
              data: {
                key: this.areaKey++,
                question: rootQuestion,
                subtopicIdx,
                questionId: rootQuestion.id, // (大题ID)
                qid: questionId, // (大题/小题 题目ID)
                isLabel,
              },
              page,
              label: rootQuestion.titleNumber || '',
              coordinate: coordinateItem,
            })
          })
        })
        return target
      }

      const result = []
      questionList.forEach((question) => {
        createAreaData(question, 0, question.id, question.isLabel, question.coordinate, result)
        if (question.isCombination && question.subtopicQuestion) {
          question.subtopicQuestion.forEach((subtopic, index) => {
            createAreaData(question, index + 1, subtopic.questionId, subtopic.isLabel, subtopic.coordinate, result)
          })
        }
      })
      return result
    },

    // 收起/显示侧边栏
    triggerFold () {
      this.asideFold = !this.asideFold
    },

    // 返回上一页
    pageBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  min-height: auto;
  display: flex;
  align-items: flex-start;
  // padding: 10px;
  // 滚动条样式
  ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  // 滚动条内部滑块样式
  ::-webkit-scrollbar-thumb{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color:#F8F8F8;
  }

  .layout-col {
    height: calc(100vh - 80px);
    margin-top: 10px;
    margin-right: 10px;
    box-sizing: border-box;
  }

  .aside {
    position: relative;
    width: 310px;
    background-color: #fff;
    flex-shrink: 0;

    .trigger-btn {
      position: absolute;
      left: 100%;
      bottom: 10px;
      width: 16px;
      height: 30px;
      background: #18C2C2;
      border-radius: 0 4px 4px 0;
      cursor: pointer;

      &:hover {
        opacity: .6;
      }

      &::before  {
        content: '';
        position: absolute;
        top: 5px;
        left: 6px;
        width: 2px;
        height: 11px;
        background-color: #FFF;

        transform: rotateZ(30deg);
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 6px;
        width: 2px;
        height: 11px;
        background-color: #FFF;

        transform: rotateZ(-30deg);
      }
    }

    &.fold {
      width: 3px;
      background: #18C2C2;

      &:hover {
        // border-right: 4px solid #ccc;
        background: rgba(24, 194, 194, 0.8);
      }

      .trigger-btn {
        &::before {
          transform: rotateZ(-30deg);
        }
        &::after {
          transform: rotateZ(30deg);
        }
      }

      .aside-container {
        display: none;
      }
    }
  }
  .left{
    width: 640px;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    .page-back {
      padding: 0;
      font-size: 18px;
    }
    .header-title{
      font-size: 14px;
      font-weight: 600;
      color: #000;
      margin-bottom: 15px;
    }
    .question-list{
      height: calc(88vh - 40px);
      overflow: auto;
      margin-right: -15px;
      padding-right: 15px;

      .qs{
        margin-bottom: 10px;
        &:nth-last-child(1){
          margin-bottom: 0;
        }
      }
      .nomoredata{
        text-align: center;
        padding: 5px 0;
        font-size: 13px;
        color: #999;
      }
    }
  }
  .right{
    flex: 1;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    .header-title-btn{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 15px;
      div{
        &:nth-child(1){
          font-size: 14px;
          font-weight: 600;
          color: #000;
        }
      }
    }
    .imgArea{
      // width: calc(100% - 30px);
      height: calc(100vh - 140px);
      .imgBox{
        height: calc(100% - 60px);
        position: relative;
        overflow: scroll;
      }
      .pageNumBox{
        height: 40px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
}
</style>
