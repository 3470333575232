<template>
  <el-input-number
    :value="num"
    :min="0"
    :max="100"
    :step="0.5"
    @input="onInput"
    step-strictly
  />
</template>

<script>
export default {
  name: 'InputScore',
  props: {
    value: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    num () {
      return this.value > 0 ? this.value : undefined
    }
  },
  methods: {
    onInput (val) {
      this.$emit('input', val)
      this.$emit('input', val)
    }
  }
}
</script>
