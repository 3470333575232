/**
 * 创建空题目内容
 * @param {Boolean} isSub 题目类型：是否小题
 * @return {Object}
 */
export function createQuestionData (isSub = false) {
  const data = {
    // questionId: '',
    titleNumber: '',
    stem: '',
    answer: '',
    analysis: '',
    chapter: [],
    knowledge: [],
    keyAccomplishment: [],
    coordinate: [],
    videoResource: {},
    // sort: 1,
    // isDelete: false,
    score: undefined,
  }
  if (!isSub) {
    data.questionType = undefined
    data.degreeOfDifficulty = undefined
    data.audioResource = {} // 听力音频
    data.resource = '' // 听力材料
  }
  return data
}

/**
 * 题目编辑区数据导出
 * @param {Object} origin 题目数据
 * @param {Boolean} isSub 题目类型：是否小题
 * @param {Object?} extend 其他属性
 */
export function getQuestionData (origin, isSub = false, extend = {}) {
  const target = {}
  // 导出独立题
  const props = [
    'titleNumber',
    'stem',
    'answer',
    'analysis',
    'knowledge',
    'keyAccomplishment',
    'audioResource',
    'resource',
    'videoResource',
    'score',
  ]
  if (!isSub) {
    props.splice(0, 0, 'questionType', 'degreeOfDifficulty')
  }
  props.forEach(k => {
    if (Object.hasOwnProperty.call(origin, k)) {
      target[k] = origin[k]
    }
  })
  return Object.assign(target, extend)
}
