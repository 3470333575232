import request from '@/utils/request'

/**
 * 获取科目
 * @returns {AxiosPromise}
 */
export function getSubjectList () {
  return request({
    url: '/basicTeachingMaterial/getSubject',
    method: 'GET',
    data: null
  })
}

/**
 * 获取标准教材搜索列表
 * @returns {AxiosPromise}
 */
export function getStandardTeachingMaterial (subject) {
  return request({
    url: '/basicTeachingMaterial/getStandardTeachingMaterial?subject=' + subject,
    method: 'GET',
    data: null
  })
}

/**
 * 获取章节统计
 * @returns {AxiosPromise}
 */
export function getTeachingMaterialStatistics (standardTeachingMaterialId, materialId) {
  return request({
    url: '/basicTeachingMaterial/getTeachingMaterialStatistics',
    method: 'GET',
    params: {
      standardTeachingMaterialId,
      materialId
    }
  })
}

/**
 * 获取章节列表
 * @returns {AxiosPromise}
 */
export function getTeachingMaterialChapter (standardTeachingMaterialId, materialId) {
  return request({
    url: '/basicTeachingMaterial/getTeachingMaterialChapter?standardTeachingMaterialId=' + standardTeachingMaterialId + '&materialId=' + materialId,
    method: 'GET',
    data: null
  })
}

/**
 * 题库标准教材图片获取
 * @param {Number} teachingMaterialId 教辅ID
 * @param {Number} stmid 标准教材ID（仅用于兼容旧版接口）
 * @returns {AxiosPromise}
 */
export function getQuestionBankImage (teachingMaterialId, stmid) {
  return request({
    url: '/common/getQuestionBankImage',
    method: 'GET',
    params: {
      standardTeachingMaterialId: stmid,
      materialId: teachingMaterialId
    }
  })
}

/**
 * 新增题库
 * @returns {AxiosPromise}
 */
export function questionBankAdd (data) {
  return request({
    url: '/question/bank/add',
    method: 'POST',
    data: data
  })
}

/**
 * 根据标准教材id获取年级科目
 * @returns {AxiosPromise}
 */
export function getSubjectAndGrade (materialId) {
  return request({
    url: '/common/getSubjectAndGrade?materialId=' + materialId,
    method: 'GET',
    data: null
  })
}

/**
 * 批量新增题目
 * @returns {AxiosPromise}
 */
export function questionWordUpload (data) {
  return request({
    url: '/common/worldUpload',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'POST',
    data: data
  })
}

/**
 * 根据课时id获取所有题目
 * @returns {AxiosPromise}
 */
export function getQuestionListByClassHourId (classHourId, materialId) {
  return request({
    url: '/common/getQuestionListByClassHourId?classHourId=' + classHourId + '&materialId=' + materialId,
    method: 'GET',
    data: null
  })
}

/**
 * 题目审核
 * @returns {AxiosPromise}
 */
export function recordQuestionAduit (data) {
  return request({
    url: '/recordQuestion/audit/bank',
    method: 'POST',
    data: data
  })
}

/**
 * 批量题目审核
 * @returns {AxiosPromise}
 */
export function recordQuestionAuditBatch (data) {
  return request({
    url: '/recordQuestion/audit/batch',
    method: 'POST',
    data: data
  })
}
